@import '../../styles/variables';

.bandeau_affichage{
  width: calc(100% - 3rem);
  background-color: rgba(220, 56, 56, 0.78);
  backdrop-filter: blur(5px);
  color: white;
  min-height: 1rem;
  padding: 1rem;
  position: fixed;
  bottom: 0.5rem;
  left: 0.5rem;
  z-index: 100000;
  box-shadow: rgba(0, 0, 0, 0.05) 0 -3px 6px, rgba(0, 0, 0, 0.09) 0 -5px 6px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  border-radius: var(--border-radius);
  p{
    white-space: pre-line;
  }
}