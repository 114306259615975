@import '../../../styles/variables';

.bandeau_user{
  background-color: $bg-color;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  padding: 0.2rem 1rem;
  border-radius: var(--border-radius);
  gap: 0.5rem;

  button{
    margin: unset;
  }
  a{
    color: unset;
  }
}