@import '../../../styles/variables';

.page_be{
  .titre{
    flex-grow: 1;
  }
  .en-tete-pages .en-tete-page {
    width: calc(100% - 2rem);
    margin: 1rem 1rem 0 1rem;
  }
}