@import 'variables';

.app{
  display: flex;
  flex-flow: row nowrap;
  gap: 0rem;
  background-color: $bg-color;
}

nav{
  width: 14rem;
  position: fixed;
  box-shadow: rgba(0, 0, 0, 0.44) 0 2px 9px, rgba(0, 0, 0, 0.09) 0 3px 6px;
}

.content{
  flex: 1;
  //margin-right: 27rem;
  //margin: 2rem 2rem 2rem 16rem;
  //margin: 2rem 27rem 2rem 16rem;
  margin: 2rem calc(20% + 6rem) 2rem 16rem;

  &.content-fullwidth{
    margin: 2rem 2rem 2rem 16rem;
  }
}

.display-content{
  display: contents !important;
  &.icone{
    svg{
      width: 0.7rem;
      height: 0.7rem;
    }

  }
}

.page-erreur{
  padding: 1rem;
}


@media screen and (max-width: $responsive-laptop13) {
  /* Ecran HP Envy 13" */
  .content {
    margin: 2rem calc(20% + 6rem) 2rem 15rem;
    &.content-fullwidth{
      margin: 2rem 2rem 2rem 15rem;
    }
  }
}
@media screen and (max-width: $responsive-laptop) {
  /* Ecran laptop standard */
}
@media screen and (max-width: $responsive-TV720) {
  /* Ecran TV 720p */
}
@media screen and (max-width: $responsive-tablette) {
  /* Tablette & phone, on cache les menus */
  .content {
    margin: 5rem 1rem 1rem 1rem;
    &.content-fullwidth{
      margin: 5rem 1rem 1rem 1rem;
    }
  }
}