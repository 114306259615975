@import '../../../styles/variables';

.modale_orga_utilisateurs{
  min-width: 50vw;
  .tableauContainer table{
    width: 100%;
    margin: 0;
  }
  h3.margintop{
    margin-top: 1rem;
  }
  .en-ligne.a_droite{
    align-items: center;
    h3{
      flex-grow: 1;
    }
  }
}
.form_permissions{
  display: flex;
  flex-flow: column wrap;
  gap: 0.5rem;
  margin: 1rem 0;
  flex: 1;
  white-space: break-spaces;
  min-width: 60%;
}

.new_user{
  display: flex;
  flex-flow: column wrap;
  gap: 1rem;
  input{
    flex-grow: 1;
  }
}