@import 'variables';
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

:root{
  --main-color-raw: 6, 54, 122;
  --main-color: #06367A;
  //--main-light-color: #266FD5;
  --main-light-color: #1F5DB7;
  //--bg-color-raw: 235, 242, 252; //OLD
  --bg-color-raw: 238, 244, 252;
  //--error-color: #e35d5d; //version pastel, contraste uniquement AA large
  --error-color: #dc3838;
  --warning-color: #eaa143;
  //--valid-color: #00b6a0; //version pastel, aucun contraste AA
  --valid-color: #26a66a;

  --grey-color: #7D8080FF;
  --bg-color: rgb(var(--bg-color-raw));

  --texte-size-titre: 1.3rem;
  --texte-size-normal: 18px;
  --texte-size-petit: 0.8rem;

  --shadow: rgba(0, 0, 0, 0.05) 0 3px 6px, rgba(0, 0, 0, 0.09) 0 3px 6px;
  --shadow-light: rgba(0, 0, 0, 0.03) 0 3px 6px, rgba(0, 0, 0, 0.04) 0 3px 6px;

  --border-radius: 8px;

}

$main-color: var(--main-color);
$bg-color: var(--bg-color);

*{
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
}

html, body{
  width: 100%;
  min-height: 100%;
  font-size: var(--texte-size-normal);
  background-color: $bg-color;
  line-height: 1rem;
}

*, *::before, *::after {
  box-sizing: unset; // Sinon mantine CSS le bouge
}

*[class*="mantine"], *[class*="mantine"]::before, *[class*="mantine"]::after {
  box-sizing: border-box; // Sinon mantine CSS le bouge
}



.only_mobile{
  display: none !important;
}
.only_mobile_contents{
  display: none !important;
}
.only_mobile_flex{
  display: none !important;
}
@media screen and (max-width: $responsive-laptop13) {
  /* Ecran HP Envy 13" */

}
@media screen and (max-width: $responsive-laptop) {
  /* Ecran laptop standard */
}
@media screen and (max-width: $responsive-TV720) {
  /* Ecran TV 720p */
  .hide_on_tv{
    display: none !important;
  }
}
@media screen and (max-width: $responsive-tablette) {
  /* Tablette & phone, on cache les menus */
  .only_mobile{
    display: unset !important;
  }
  .only_mobile_flex{
    display: flex !important;
  }
  .only_mobile_contents{
    display: contents !important;
  }
  .hide_on_mobile{
    display: none !important;
  }
}


