@import '../../styles/variables';

.svg-loader{
  position: relative;
  .loader-outside{
    animation:spin 8s linear infinite;
  }
  .loader-inside{
    animation:spin 2.5s linear infinite reverse;
    position: absolute;
    top: 0;
    left: 0;
  }
}

button.big{
  .icone{
    height: 1em;
  }
}

.icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1em;
  /*min-width: 30px;*/
  rotate: 0deg;
  transition: rotate ease 200ms;

  svg {
    width: 1em;
    height: 1em;

  }

  &.rotate{
    rotate: 90deg;
  }
  &.red{
    color: var(--error-color);
  }
  &.green{
    color: var(--valid-color);
  }
  &.orange{
    color: var(--warning-color);
  }
}

@-moz-keyframes spin {
  100% { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  100% { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform:rotate(360deg);
  }
}