@import '../../../styles/variables';

.app.inscription{
  min-height: 100svh; //Changé depuis height:100svh pour la page d'estimation des couts
  background: rgb(3,148,180);
  background: linear-gradient(-15deg, rgba(3,148,180,1) 0%, rgba(6,54,122,1) 100%);
  justify-content: center;
  align-items: center;
  .inscription_form{
    max-width: calc(100% - 2rem);
    width: 70rem;
    //min-height: calc(100% - 6rem);
    background-color: rgba(var(--bg-color-raw),0.7);
    display: flex;
    flex-flow: column nowrap;
    //justify-content: center;
    align-items: center;
    border-radius: var(--border-radius);
    border-top: solid 4rem rgba(var(--bg-color-raw),1);
    img{
      //position: absolute;
      margin-top: -3rem;
      width: 10rem;
    }
    .stepper_inscription-page{
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      gap: 1rem;
      padding: 1rem;
      //color: var(--main-color);
      &>*{
        //width: calc(50% - 0.5rem);
      }
      .carte_mesure{
        width: 10rem;
      }
      .fullwidth{
        width: 100%;
      }
      input:not([type="checkbox"]){
        width: 20rem;
      }
    }
  }

  .mantine-Stepper-root{
    width: calc(100% - 2rem);
    margin-top: 2rem;
  }


}

@media screen and (max-width: $responsive-tablette) {
  /* Tablette & phone, on cache les menus */
  .app.inscription{

    .inscription_form {
      max-width: 100%;
      min-height: calc(100svh - 4rem);
    }
  }
}