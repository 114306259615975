@import '../../styles/variables';

.logo{
  background-color: white;
  width: calc(100% - 5rem);
  margin: 0 1rem;
  padding: 3rem 1.5rem 1.5rem 1.5rem;
  border-radius: 0 0 15px 15px;
  box-shadow: #00000070 0 3px 5px 0;
}

nav{
  background: rgb(3,148,180);
  background: linear-gradient(0deg, rgba(3,148,180,1) 0%, rgba(6,54,122,1) 100%);
  height: calc(100svh - 1rem);
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  padding-bottom: 1rem;
  overflow: auto;

  ul{
    display: flex;
    flex-flow: column nowrap;
    gap: 0.5rem;
    li{
      margin: 0 1rem;
      list-style-type: none;
      border-radius: var(--border-radius);
      cursor: pointer;
      &.selected{
        background-color: #00275C;
      }
      &:hover{
        background-color: #0e3c7b;
      }
      a{
        color: white;
        width: calc(100% - 1.5rem);
        padding: 0.5rem 0 0.5rem 1.5rem;
        font-style: normal;
        text-decoration: none;
        display: flex;
        flex-flow: row nowrap;
        gap: 0.5rem;
      }
    }
  }
  .separator{
    flex: 1;
  }

  &.hide{
    display: flex;
  }


}

.orgas_nav{
  .mantine-Menu-item, a.mantine-Menu-item, a.mantine-Menu-item:hover{
    filter: unset !important;
    padding: unset;
    a{
      padding: calc(var(--mantine-spacing-xs) / 1.5) var(--mantine-spacing-sm);
      display: block;
    }
  }
}

.mobile_header{
  display: none;
}

@media screen and (max-width: $responsive-laptop13) {
  /* Ecran HP Envy 13" */
  nav{
    width: 13rem;
  }
}
@media screen and (max-width: $responsive-laptop) {
  /* Ecran laptop standard */
}
@media screen and (max-width: $responsive-TV720) {
  /* Ecran TV 720p */
}
@media screen and (max-width: $responsive-tablette) {
  /* Tablette & phone, on cache les menus */
  nav{
    //display: none;
    z-index: 200;
    width: 13rem;

    padding-top: 1rem;
    padding-bottom: 1rem;
    height: calc(100dvh - 2rem);
    transform: translateX(-100%);
    transition: transform 0.5s ease-in-out;
    .logo-link{
      display: none;
    }
    &.open{
      display: flex;
      transform: translateX(0%);
      transition: transform 0.5s ease-in-out;
    }
    &.hide{
      display: none;
    }
  }

  .mobile_header{
    position: fixed;
    top: 0;
    left: 0;
    width: calc(100% - 2rem);
    height: 4rem;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    background: rgb(3,148,180);
    background: linear-gradient(90deg, rgba(3,148,180,1) 0%, rgba(6,54,122,1) 100%);
    padding: 0 1rem;
    z-index: 101;
    box-shadow: var(--shadow);

    .logo-link{
      align-self: start;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      .logo{
        height: 1.3rem;
        padding: 1.2rem 0.8rem 0.8rem 0.8rem;
        max-width: 100%;
      }
    }

    .mobile_trigger_notifications{
      color: #fff;
      font-size: 1.1rem;
      padding-left: calc((0.625rem / 2) + 0.3rem);
      padding-right: calc((0.625rem / 2) + 0.3rem);
      padding-top: calc((0.625rem / 2) + 0.3rem);
      height: 2rem + 0.625rem;
      margin-top: 2rem - (0.625rem * 1.5) - 0.3rem;
      margin-right: -0.3rem;
      border-radius: var(--border-radius);
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out;
      &.open{
        background-color: white;
        color: var(--main-color);
        &:after{
          opacity: 1;
        }
      }
      &:after{
        content: "";
        position: absolute;
        bottom: -15px;
        left: 0;
        width: 100%;
        height: 15px;
        background-color: white;
        transition: opacity 0.5s ease-in-out;
        opacity: 0;
      }
    }

    .mantine-Burger-root{
      margin: unset;
    }

    .nav_background{
      position: absolute;
      display: flex;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100dvh;
      z-index: 190;
      background-color: rgba(34, 34, 34, 0.65);
      backdrop-filter: blur(4px);
      opacity: 0;
      transition: opacity 0.5s ease-in-out;
      &.open{
        transition: opacity 0.5s ease-in-out;
        opacity: 1;
        display: flex;
      }
      &.hide{
        display: none;
      }
    }
  }
}