@import '../../../styles/variables';

.organisations{
  display: flex;
  flex-flow: column nowrap;
  gap: 2rem;
  .recherche{
    position: relative;
    width: 100%;
    .icone{
      position: absolute;
      top: 1.4rem;
      left: 1.4rem;
    }
    .big_input{
      padding: 1.2rem 1.2rem 1.2rem 3.3rem;
      width: calc(100% - 4.5rem);
      box-shadow: var(--shadow);
    }
  }

}

.notifications{
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  margin-top: 1rem;
  h3 {
    font-size: var(--texte-size-normal);
    font-weight: lighter;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0.5rem;
  }

  .notif-en-tete{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0.2rem;
    .icone.svg-bell{
      color: var(--error-color);
      opacity: 100%;
    }
    .icone.svg-bullhorn{
      color: var(--warning-color);
      opacity: 100%;
    }
    &.resolution{
      .icone.svg-bell{
        color: var(--valid-color);
        opacity: 100%;
      }
    }
  }

  .titre {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    p{
      font-weight: bold;
    }
    i{
      font-weight: lighter;
      font-style: normal;
      white-space: nowrap;
    }
  }

  .infos {
    font-weight: normal;
    font-style: italic;
    font-size: var(--texte-size-normal);
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
    button{
      margin: unset;
    }
  }
  .carte_notification {
    background-color: var(--bg-color);
    padding: 0.3rem 0.6rem;
    border-radius: var(--border-radius);
    //border-left: var(--main-color) solid 2px;
    background: #fff linear-gradient(173deg, #fff 40%, rgb(235, 242, 252) 100%);
    box-shadow: var(--shadow-light);
    font-size: var(--texte-size-petit);
    display: flex;
    flex-flow: column nowrap;
    gap: 0.5rem;
    border: rgba(var(--main-color-raw), 0.05) solid 1px;

    &.jaune{
      //border-left-color: #e3d85d;
    }
    &.red{
      //border-left-color: var(--error-color);
      cursor: pointer;
    }
  }
}