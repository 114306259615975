$main-color: var(--main-color);

$bg-color: var(--bg-color);

$main-light-color: var(--main-light-color);

$error-color: var(--error-color);

$warning-color: var(--warning-color);

$valid-color: var(--valid-color);

$texte-size-titre: var(--texte-size-titre);
$texte-size-normal: var(--texte-size-normal);
$texte-size-petit: var(--texte-size-petit);

$border-radius: var(--border-radius);


$responsive-laptop13: 1900px;
$responsive-laptop: 1500px;
$responsive-TV720: 1350px;
$responsive-tablette: 1200px;
$responsive-phone: 992px; //utilisé pour le tableau