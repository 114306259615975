@import '../../../styles/variables';

.page_mon_compte{
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  form{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    &>*{
      width: calc(50% - 1rem);
    }
    .fullWidth{
      width: 100%;
    }
    .checkbox{
      margin-top: 1rem;
      margin-bottom: -0.5rem;
    }
  }
  .en-tete-page{
    width: calc(100% - 0rem);
    //margin: 0 1rem 1rem 1rem;
    justify-content: space-between;
  }
  #zone_push{
    padding: 0.5rem;
    background: #fff;
    border-radius: var(--border-radius);
    display: flex;
    flex-flow: column nowrap;
    gap: 0.5rem;
    box-shadow: var(--shadow);
  }
}