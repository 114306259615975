@import '../../../styles/variables';

.page-en-direct{
  margin-left: 2rem;

  .organisations {
    gap: 1rem;
  }

  h2, h3{
    font-size: var(--texte-size-titre);
    width: fit-content;
    max-width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0.5rem;
    font-weight: normal;
    cursor: unset;
    color: var(--main-color);
  }
  h2{
    font-weight: bold;
  }

  .en-direct-reglages{
    width: calc(100% - 2rem);
    padding: 1rem;
    background-color: white;
    border-radius: var(--border-radius);
    box-shadow: var(--shadow);
    display: flex;
    flex-flow: column nowrap;
    gap: 0.5rem;
    height: -moz-fit-content;
    height: fit-content;
    h3{
      font-size: var(--texte-size-normal);
      font-weight: lighter;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 0.5rem;
      color: black;
    }
  }

  &.en_direct_fullscreen{
    margin-right: 2rem;
  }

}

.en-direct-texte{
  color: var(--error-color);
  margin-right: auto;
}

@media screen and (max-width: $responsive-tablette) {
  /* Tablette & phone, on cache les menus */
  .page-en-direct {
    margin-left: 1rem;
    margin-top: 1rem;
    width: calc(100% - 2rem);
  }
}