@import '../../../../styles/variables';

.accueil_organisation{
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5rem;
  h2, h3{
    font-size: var(--texte-size-titre);
    width: fit-content;
    max-width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0.5rem;
    font-weight: normal;
    cursor: pointer;
    color: var(--main-color);
  }
  h2{
    font-weight: bold;
  }
  .zones{
    margin-left: 0.5rem;
    display: flex;
    flex-flow: column nowrap;
    gap: 0.5rem;
  }
  .en-tete-orga{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 0.5rem;
    button{
      margin: unset;
    }
  }

}

@media screen and (max-width: $responsive-laptop13) {
  /* Ecran HP Envy 13" */

}
@media screen and (max-width: $responsive-laptop) {
  /* Ecran laptop standard */
}
@media screen and (max-width: $responsive-TV720) {
  /* Ecran TV 720p */
}
@media screen and (max-width: $responsive-tablette) {
  /* Tablette & phone, on cache les menus */
  .accueil_organisation .zones {
    margin-left: 0;
  }
  .accueil_organisation .en-tete-orga button {
    margin: auto;
  }
  .zone{
    h3{
      font-size: calc($texte-size-normal + 0.2rem);
    }
  }
}