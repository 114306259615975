@import '../../../styles/variables';

.page_capteur{
  h1 {
    //text-align: center;
    font-size: var(--texte-size-titre);
    margin-bottom: 1rem;
    margin-top: 0.2rem;
    color: var(--main-color);
  }

  .en-tete{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    div{

    }

    button{
      margin: unset;
      width: fit-content;
    }
  }

  h2 {
    display: flex;
    align-items: center;
    gap: 0.2rem;
    font-size: var(--texte-size-normal);
    font-weight: lighter;
    color: var(--main-color);

    strong {
      font-weight: normal;
    }
  }

  .nomCapteur{
    //width: calc(100% - 1.2rem);
    color: var(--main-color);
    font-size: var(--texte-size-titre);
    margin-bottom: 1rem;
    input{
      font-size: var(--texte-size-titre);
    }
  }

  .extensions{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    margin-top: 1rem;
    input{
      //width: calc(100% - 1.2rem);
      color: var(--main-color);
    }
    .extension{
      width: calc(50% - 1.5rem);
      //border-left: solid var(--main-color) 2px;
      //padding-left: 0.5rem;
      display: flex;
      flex-flow: column wrap;
      gap: 0.25rem;

      padding: 0.5rem;
      border-radius: var(--border-radius);
      box-shadow: var(--shadow);
      background: #fff linear-gradient(173deg, #fff 36%, rgba(var(--bg-color-raw), 1) 100%);

      &.selected{
        //border-left: solid var(--valid-color) 4px;
        background: rgb(0,182,160);
        background: linear-gradient(173deg, #fff 36%, rgba(0,182,160,0.2) 100%);
        //padding-top: 1rem;
        //padding-bottom: 1rem;
      }

      h3{
        font-size: var(--texte-size-normal);
        padding-left: 0.5rem;
      }

      .infos{
        padding-left: 0.5rem;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        gap: 1rem;
        justify-content: space-between;
        width: calc(100% - 0.6rem);
        font-size: var(--texte-size-petit);
        font-weight: lighter;

        button{
          margin: unset;
        }
      }
    }
  }
}

@media screen and (max-width: $responsive-laptop13) {
  /* Ecran HP Envy 13" */
}
@media screen and (max-width: $responsive-laptop) {
  /* Ecran laptop standard */
}
@media screen and (max-width: $responsive-TV720) {
  /* Ecran TV 720p */
}
@media screen and (max-width: $responsive-tablette) {
  /* Tablette & phone, on cache les menus */
  .page_capteur .extensions .extension{
    width: calc(100% - 1rem);
  }
}
