@import '../../styles/variables';

$nbe_mesures_par_lignes: 4;
$gap_entre_mesure: 0.5rem;

.mesures{
  display: flex;
  flex-flow: row wrap;
  gap: 0.5rem;
  margin-top: 0.25rem;
  margin-left: 0.5rem;
}
.page_mesure{
  margin-bottom: 0;
}
.mesures_historique{
  display: flex;
  flex-flow: row wrap;
  gap: 0.5rem;
  //margin-top: 1rem;
  //overflow-x: hidden;
  overflow-y: scroll;
  max-height: calc(100svh - 11rem - 6px);
  padding: 0.5rem 1rem 2rem 1rem;
  margin: 0.5rem -1rem 0 -1rem;
  //-ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: thin;  /* Firefox */
  /* Hide scrollbar for Chrome, Safari and Opera */
  //&::-webkit-scrollbar{
  //  display: none;
  //}

  .carte_mesure{
    $mh_nbe_mesures_par_lignes: 6;
    $mh_gap_entre_mesure: 0.5rem;
    width: calc( (100%/$mh_nbe_mesures_par_lignes) - ((( ($mh_nbe_mesures_par_lignes - 1) * $mh_gap_entre_mesure) / $mh_nbe_mesures_par_lignes) ) );
  }
}
.vue-resume{
  justify-content: center;
}

.carte_mesure{
  display: flex;
  flex-flow: column nowrap;
  color: var(--main-light-color);
  //background-color: var(--main-light-color);
  background-color: white!important;
  border-radius: 12px 12px var(--border-radius) var(--border-radius);
  width: calc( (100%/$nbe_mesures_par_lignes) - ((( ($nbe_mesures_par_lignes - 1) * $gap_entre_mesure) / $nbe_mesures_par_lignes) ) );
  box-shadow: var(--shadow);
  //position: relative;

  //filter: drop-shadow( 2px 2px 4px rgba(34, 34, 34, 0.34));
  .titre{
    display: flex;
    flex-flow: row nowrap;
    //background-color: white;
    width: calc(100% - 0.8rem);
    border-radius: var(--border-radius) var(--border-radius) 0 0;
    gap: 0.3rem;
    padding: 0 0.4rem;
    .titre_container{
      width: 100%;
      overflow: hidden;
      //position: relative;
      height: 2rem;
      .titre_texte{
        line-height: 2rem;
        white-space: nowrap;
        width: 100%;
        max-width: 50vw;
        //text-overflow: ellipsis;
        //position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        i{
          font-style: italic;
          font-size: var(--texte-size-petit);
          font-weight: lighter;
        }
        &.slide{
          //animation: slide 5s linear infinite alternate;
          //padding-left: 5px;
          //padding-right: 5px;
        }
      }
    }
  }
  .valeur{
    font-size: var(--texte-size-titre);
    padding: 0.5rem;
    justify-self: center;
    align-self: center;
    font-weight: bold;
    color: white;
    color: var(--main-light-color);
    min-height: 4rem;
    text-align: center;
    display: flex;
    align-items: center;
    flex: 1;
  }
  &.bg_color{
    .titre, .conditions{
      background-color: var(--bg-color);
    }

  }
  .conditions{
    //background-color: white;
    display: flex;
    flex-flow: row wrap;
    gap: 0.6rem;
    align-items: center;
    border-radius: 0 0 var(--border-radius) var(--border-radius) ;
    padding: 0.2rem 0.5rem;
    font-size: var(--texte-size-petit);
    .item{
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      gap: 0.2rem;
      &.bleu{
        color: var(--main-light-color);
      }
      &.green{
        color: var(--valid-color);
      }
      &.orange{
        color: #eaa143;
      }
      &.red{
        color: var(--error-color);
      }
    }
  }
  &.gps{
    color: var(--main-light-color);
    background-color: var(--main-light-color);
    .valeur{
      font-size: var(--texte-size-normal);
      font-style: italic;
      font-weight: normal;
    }
  }
  &.green{
    color: var(--main-color);
    //color: var(--valid-color);
    //background-color: var(--valid-color);
    border-bottom: solid 3px var(--valid-color);
    .valeur{
      color: var(--main-color);
    }
  }
  &.grey{
    color: var(--grey-color);
    background-color: var(--grey-color);
    border-bottom: solid 3px var(--grey-color);
    .valeur{
      color: var(--grey-color);
    }
  }
  &.red{
    color: white;
    background-color: var(--error-color) !important;
    border-bottom: solid 3px #7f1616;
    .valeur{
      color: white;
    }

  }
  &.orange{
    color: var(--warning-color);
    border-bottom: solid 3px var(--warning-color);
    .valeur{
      color: var(--warning-color);
    }
  }
  &.blue, &.bleu{
    color: var(--main-light-color);
    background-color: var(--main-light-color);
    border-bottom: solid 3px var(--main-light-color);
  }
  &.white{
    color: var(--main-light-color);
    background-color: white;
    .titre_texte{
      justify-content: center;
    }
    .valeur{
      color: inherit;
      font-size: 2rem;
    }
  }
  &.selected{
    .titre, .conditions{
      background-color: var(--warning-color);
      color: white;
    }

  }


  .icone.green{
    //color: var(--valid-color);
    color: unset;
  }

  &.loading_anim{
    animation: skeleton-opacity 1.5s ease-in-out infinite;
  }
  @keyframes skeleton-opacity {
    0% {
      opacity: 1; /* Start with full opacity */
    }
    50% {
      opacity: 0.5; /* Fade to half opacity */
    }
    100% {
      opacity: 1; /* Return to full opacity */
    }
  }

  cursor: pointer;
  &:hover{
    filter: brightness(110%);
  }



  //position: relative;
  .btn_custom_carte_mesure{
    //margin-top: -7.5rem;
    //margin-bottom: 5.5rem;
    margin-left: 0.5rem;
    height: 2rem;
    margin-bottom: -2rem;
    z-index: 10;
  }

}

//@keyframes slide {
//  0% {
//    transform: translateX(1rem);
//  }
//  100% {
//    transform: translateX(calc(-100% + 1rem));
//  }
//}


@media screen and (max-width: $responsive-laptop13) {
  /* Ecran HP Envy 13" */
}
@media screen and (max-width: $responsive-laptop) {

}
@media screen and (max-width: $responsive-TV720) {
  $nbe_mesures_par_lignes: 3;
  .carte_mesure{
    width: calc( (100%/$nbe_mesures_par_lignes) - ((( ($nbe_mesures_par_lignes - 1) * $gap_entre_mesure) / $nbe_mesures_par_lignes) ) );
  }
  .mesures_historique{
    .carte_mesure{
      $mh_nbe_mesures_par_lignes: 6;
      $mh_gap_entre_mesure: 0.5rem;
      width: calc( (100%/$mh_nbe_mesures_par_lignes) - ((( ($mh_nbe_mesures_par_lignes - 1) * $mh_gap_entre_mesure) / $mh_nbe_mesures_par_lignes) ) );
      .titre{
        font-size: calc($texte-size-normal - 2px);
      }
      .valeur{
        font-size: $texte-size-titre - 0.2rem;
      }
    }
  }
}
@media screen and (max-width: $responsive-tablette) {
  /* Tablette & phone, on cache les menus */

  .mesures {
    margin-left: 0;
  }

  $nbe_mesures_par_lignes: 2;
  .carte_mesure{
    width: calc( (100%/$nbe_mesures_par_lignes) - ((( ($nbe_mesures_par_lignes - 1) * $gap_entre_mesure) / $nbe_mesures_par_lignes) ) );
    box-shadow: none; // NE PAS ENLEVER, PROVOQUE DES BUGS D'affichage sur Chrome Android quand utilisé avec le border-radius.
    border-bottom: none !important; // NE PAS ENLEVER, PROVOQUE DES BUGS D'affichage sur Chrome Android quand utilisé avec le border-radius.
    &.green{
      //color: var(--main-color);
      color: var(--valid-color);
      //background-color: var(--valid-color);
      .valeur{
        color: var(--valid-color);
      }
    }

    .inline-tag{
      word-wrap: anywhere;
    }
  }

  .page_mesure{
    margin-bottom: 1rem;
  }

  .mesures_historique{
    display: flex;
    flex-flow: row wrap;
    gap: 0.5rem;
    overflow-x: unset;
    overflow-y: unset;
    max-height: unset;
    padding: unset;
    margin: unset;
    margin-top: 1rem;

    .carte_mesure{
      $mh_nbe_mesures_par_lignes: 3;
      $mh_gap_entre_mesure: 0.5rem;
      width: calc( (100%/$mh_nbe_mesures_par_lignes) - ((( ($mh_nbe_mesures_par_lignes - 1) * $mh_gap_entre_mesure) / $mh_nbe_mesures_par_lignes) ) );
    }
  }
}