@import '../../../styles/variables';

.page_paiement{
  display: flex;
  flex-flow: column nowrap;
  //gap: 2rem;
  .recherche{
    position: relative;
    width: 100%;
    margin-bottom: 1rem;
    //.icone{
    //  position: absolute;
    //  top: 1.4rem;
    //  left: 1.4rem;
    //}
    .paiement_input_recherche button{ //big_input
      //padding: 1.2rem 1.2rem 1.2rem;
      width: calc(100% - 0rem);
      box-shadow: var(--shadow);
      font-size: var(--texte-size-titre);
      //box-sizing: border-box;
    }
  }

  .carte_capteur_collapse{
    display: flex;
    flex-flow: row wrap;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    background: white;
    border-radius: var(--border-radius);
    cursor: pointer;
    align-items: center;

    &.open{
      border-radius: var(--border-radius) var(--border-radius) 0 0;
    }

    .prix{
      margin-left: auto;
      font-size: 1.3rem;
    }
  }

  .tableauContainer table{
    margin-top: -0.5rem;
    border-radius: 0 0 var(--border-radius) var(--border-radius);
  }

  table tbody {
    &:before{
      content: none !important;
      font-size: var(--texte-size-normal);
    }
  }

}

.paiement_modale_rechargement_intro_texte{
  font-size: var(--texte-size-petit);
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.paragraph_promo_dans_modal{
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  min-width: 60vw;
  p{
    width: 100%;
  }
  li{
    margin-left: 1rem;
    margin-top: 0.4rem;
  }
}

.Modale_enregistrer_cb{
  .mantine-Modal-inner{
    padding-top: unset;
    padding-bottom: unset;
  }
  .mantine-Modal-body{
    height: calc(100% - 4rem);
    max-height: unset;
    .form{
      height: 100%;
      iframe{
        height: 100%;
      }
    }
  }
}