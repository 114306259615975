@import '../../styles/variables';

.loading_div {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 1rem;
  .icone svg{
    height: 2rem;
  }
}