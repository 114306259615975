@import '../../../../styles/variables';

.form_admin{
  margin-top: 1rem;
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  &>*{
    width: calc(50% - 1rem);
  }

  .fullwidth{
    width: 100%;
    button{
      margin: unset;
      width: 100%;
    }
  }
}