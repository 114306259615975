@import '../../styles/variables';

.fs_loading{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  //background-color: rgba(34, 34, 34, 0.75);
  //background-color: rgb(199, 210, 229);
  background-color: rgb(237, 243, 251);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //color: white;
  color: black;
  backdrop-filter: blur(10px);
  gap: 1rem;
  z-index: 399;
  .svg_anim_loader{
    width: 2rem;
  }
}