@import '../../../../styles/variables';

.primes-input{
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5rem;

  .en-ligne{
    width: 100%;
  }

  input{
    width: max-content;
  }

  .prime{
    display: flex;
    flex-flow: row wrap;
    background-color: #d9d9d9;
    padding: 0.25rem;
    border-radius: var(--border-radius);
    justify-content: space-between;
    align-items: center;
  }
}

.cartes_resultat_calcul_wrapper{
  background-color: white;
  padding: 0.5rem;
  border-radius: var(--border-radius);
}

.cartes_resultat_calcul{
  display: flex;
  flex-flow: row nowrap;
  margin-top: 0.5rem;
  gap: 0.5rem;
  justify-content: center;
  align-items: stretch;
  .carte{
    padding: 0.25rem;
    background-color: var(--bg-color);
    border-radius: var(--border-radius);
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    & > p{
      width: 100%;
    }
    .prix{
      text-align: center;
      font-size: var(--texte-size-titre);
      margin: 1rem 0;
    }
    .en-ligne{
      width: 100%;
    }
  }
}

.enregistrer_un_paiement{
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5rem;
  margin-top: 1rem;
}