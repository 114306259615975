@import 'src/styles/variables';

.sidebar{
  //width: 25rem - 2rem - 1rem*2;
  width: 20%;
  margin: 2rem 2rem 5px 0;
  padding: 1rem;
  background-color: white;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow);
  height: fit-content;
  max-height: calc(100svh - 6rem);
  overflow: auto;
  height: unset;

  position: fixed;
  top: 0;
  right: 0;

  //-ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: thin;  /* Firefox */


}
.mini-sidebar{
  //width: 25rem - 2rem - 1rem*2;
  //margin: 2rem 2rem 5px 0;
  padding: 0.5rem;
  background-color: white;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow);
  height: fit-content;
  max-height: calc(100svh - 6rem);
  overflow: auto;
  .bandeau_user{
    background-color: white;
    //padding-left: 4rem;
  }
}

.actions{
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  margin-top: 1rem;
  h3 {
    font-size: var(--texte-size-normal);
    font-weight: lighter;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0.5rem;
  }

  .titre {
    font-weight: bold;
  }

  .infos {
    font-weight: lighter;
    font-size: var(--texte-size-petit);
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
    button{
      margin: unset;
    }
  }
  .carte_action {
    //background-color: var(--bg-color);
    padding: 0.3rem 0.6rem;
    border-radius: var(--border-radius);
    //border-left: var(--main-color) solid 2px;
    font-size: var(--texte-size-petit);
    display: flex;
    flex-flow: column nowrap;
    gap: 0.5rem;

    border: rgba(var(--main-color-raw), 0.11) solid 1px;
    background: #fff linear-gradient(173deg, #fff 30%, rgba(var(--bg-color-raw), 1) 100%);
    box-shadow: var(--shadow-light);

    .tags {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      gap: 0.5rem;
      .tag{
        font-weight: lighter;
        background-color: white;
        padding: 0.15rem 0.3rem;
        border-radius: 5px;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        border: rgba(var(--main-color-raw), 0.08) solid 1px;
        .items{
          display: flex;
          .item:after{
            content: "-";
            padding: 0 0.2rem;
          }
          .item:last-of-type:after{
            content: "";
            padding: 0;
          }
        }

        &.red{
          color: var(--error-color);
        }
        &.green{
          background: var(--valid-color);
          color: white;
        }
      }

    }

    &.prestataire{
      border-left-color: var(--warning-color);
    }
    &.rouge{
      border-left-color: var(--error-color);
    }
  }
}
.mantine-Tabs-panel .actions{
  margin-top: 0;
}

@media screen and (max-width: $responsive-laptop13) {
  /* Ecran HP Envy 13" */

}
@media screen and (max-width: $responsive-laptop) {
  /* Ecran laptop standard */
}
@media screen and (max-width: $responsive-TV720) {
  /* Ecran TV 720p */
}
@media screen and (max-width: $responsive-tablette) {
  /* Tablette & phone, on cache les menus */
  .sidebar{
    //opacity:0%;
    transform: translateY(-100%);
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    box-shadow: unset;
    border-bottom: solid white 2px;
    display: block;
    z-index: 0;
    min-height: calc(100dvh - 6rem);
  }
  .sidebar.mobile_visible{
    opacity:100%;
    transform: translateY(0%);
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    z-index: 95;
  }
  .sidebar.mobile_visible_hide{
    //display: none;
    z-index: 0;
  }
  .sidebar{
    width: calc(100% - 2rem);
    margin: 4rem 0 0 0;

  }

  .mini-sidebar{
    display: none;
  }

  .react-joyride__overlay{
    pointer-events: none !important;
  }
  .react-joyride__spotlight{
    //pointer-events: none !important;
  }
}