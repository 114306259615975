@import '../../../styles/variables';

.page_orga{
  margin-bottom: 0;
  h1 {
    //text-align: center;
    font-size: var(--texte-size-titre);
    //margin: 1rem 0;
    color: var(--main-color);
  }

  position: relative;

  .en-tete{
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    //
    //&.fixed{
    //  position: fixed;
    //  top: 0;
    //  left: 0;
    //  right: 0;
    //  margin: 2rem calc(20% + 6rem) 2rem 15rem;
    //  padding-top: 2rem;
    //  margin-top: 0;
    //  margin-bottom: 0;
    //  width: unset;
    //  background-color: var(--bg-color);
    //  z-index: 10;
    //}


    h1{
      color: black;
      flex: 1;
    }
    button{
      margin: unset;
    }
    .boutons{
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      gap: 1rem;
    }
  }

  h2 {
    font-size: var(--texte-size-normal);
    font-weight: lighter;
    color: var(--main-color);

    strong {
      font-weight: normal;
    }
  }

  .nomCapteur{
    width: calc(100% - 1.2rem);
    color: var(--main-color);
    font-size: var(--texte-size-titre);
    margin-bottom: 1rem;
  }


  .zones{
    display: flex;
    flex-flow: column nowrap;
    margin-top: 1rem;
    gap: 2rem;

    .zone{
      display: flex;
      flex-flow: column nowrap;
      //border-left: 2px solid rgba(var(--main-color-raw), 0.5) ;
      //padding-left: 0.5rem;
      gap: 0.5rem;
      .en-tete{
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
        h4{
          font-size: var(--texte-size-normal);
          margin-left: 0.5rem;
        }
      }
      input{
        color: var(--main-color);
      }
      .mesures{
        margin-left: unset;
      }
      &.nouvelle-zone{
        background-color: rgba(0, 182, 160, 0);
        opacity: 1;
        animation: nouvelle-zone-fade-in 2s ease-in;
      }
    }
    //&.scrollable{
    //  overflow-x: hidden;
    //  overflow-y: scroll;
    //  max-height: calc(100vh - 11.5rem);
    //  padding-bottom: 2rem;
    //  -ms-overflow-style: none;  /* IE and Edge */
    //  scrollbar-width: none;  /* Firefox */
    //  /* Hide scrollbar for Chrome, Safari and Opera */
    //  &::-webkit-scrollbar{
    //    display: none;
    //  }
    //}
    &.scrollable{
      padding-bottom: 2rem;
      //margin-top: 4rem;
    }
  }
}


@keyframes nouvelle-zone-fade-in {
  0% {
    background-color: rgba(0, 182, 160, 1);
  }
  100% {
    background-color: rgba(0, 182, 160, 0);
  }
}

@media screen and (max-width: $responsive-laptop13) {
  /* Ecran HP Envy 13" */
}
@media screen and (max-width: $responsive-laptop) {
  /* Ecran laptop standard */
}
@media screen and (max-width: $responsive-TV720) {
  /* Ecran TV 720p */
  .page_orga .en-tete .boutons {
    gap: 0.5rem;
  }
}
@media screen and (max-width: $responsive-tablette) {
  /* Tablette & phone, on cache les menus */
}
