@import '../../styles/variables';

button.loading{
  animation-name: fadeBackground;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  color: white;
}

@keyframes fadeBackground {
  0% {
    background-color: rgba(var(--main-color-raw), 0.9);
  }
  50% {
    background-color: rgba(var(--main-color-raw), 0.5);
  }
  100% {
    background-color: rgba(var(--main-color-raw), 0.9);
  }
}
