@import 'variables';

.notice{
  background-color: var(--error-color);
  color: white;
  padding: 0.2rem 0.5rem;
  border-left: #c24a4a solid 0.5rem;
  border-radius: var(--border-radius);
  &.green{
    background-color: var(--valid-color);
    border-left: #149a8a solid 0.5rem;
  }
  &.grey{
    background-color: var(--grey-color);
    border-left: #595b5b solid 0.5rem;
  }
  &.blue{
    background-color: var(--main-light-color);
    border-left: var(--main-color) solid 0.5rem;
  }
}

.clickable{
  cursor: pointer;
}

.inline-tag{
  width: fit-content; //ajouté recemment
  padding: 0.2rem;
  background-color: var(--bg-color);
  border-radius: var(--border-radius);
  font-style: italic;
  font-size: var(--texte-size-petit);
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 0.2rem;
  justify-content: center; // Pour les tags ON/OFF tu tableau HUB
  &.green{
    background-color: var(--valid-color);
    color: white;
  }
  &.red{
    background-color: var(--error-color);
    color: white;
  }
  &.orange{
    background-color: var(--warning-color);
    color: white;
  }
  &.grey{
    background-color: transparent;
    color: grey;
    box-shadow: grey 0 0 0 1px;
  }
  &.grey-full{
    background-color: #b2b2b2;
    color: white;
  }
}
.tag-avec-icone{
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  .icone{
    font-size: 2rem;
  }
}

.flex-col{
  display: flex;
  flex-flow: column wrap;
}

.flex-col-center{
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
}

.en-ligne{
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 0.5rem;

  &.a_droite{
    justify-content: end;
    button{
      margin: unset;
    }
  }

  &.spacebetween{
    justify-content: space-between;
    button{
      margin: unset;
    }

  &.spaceevenly {
    justify-content: space-evenly;

    button {
      margin: unset;
    }
  }
  }
}
.no-wrap{
  white-space: nowrap;
  flex-wrap: nowrap;
}
.wrap-text{
  white-space: unset;
}

.hide{
  display: none;
  //display: none !important; // enelvé le !important pour la navbar en FHD
}

.flex_align_center{
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}

.justify_center{
  justify-content: center !important;
}
.justify_end{
  justify-content: flex-end !important;
}

.paddingZero{
  padding: 0 !important;
}


.noMargin{
  margin: unset !important;
}

.texte-petit{
  font-size: var(--texte-size-petit);
}

.texte-normal{
  font-size: var(--texte-size-normal);
}

.texte-info{
  font-size: var(--texte-size-petit);
  opacity: 0.5;
}

.espace-entre-items{
  margin-bottom: 0.5rem;
}
.espace-entre-items:last-of-type{
  margin-bottom: 0;
}

.accordion-flex-row{
  .mantine-Accordion-content{
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    gap: 0.5rem;
  }
}

.no-italic{
  font-style: unset;
}


.flex-row{
  display: flex;
  flex-flow: row wrap;
  gap: 0.5rem;
  align-items: center;
}

.description-custom-multi-select{
  font-size: var(--texte-size-petit);
  white-space: break-spaces;
  opacity: 70%;
  font-weight: lighter;
}

.margintop{
  margin-top: 1rem;
}

@media screen and (max-width:$responsive-tablette) {
  .mobile-wrap{
    flex-wrap: wrap !important;
  }
}

@media screen and (max-width:$responsive-laptop) {
  .laptop-wrap{
    flex-wrap: wrap !important;
  }
}

@media screen and (max-width:$responsive-TV720) {
  .tv-wrap{
    flex-wrap: wrap !important;
  }
}

