@import '../../../../styles/variables';

.app.inscription-token{
  height: 100svh;
  background: rgb(3,148,180);
  background: linear-gradient(-15deg, rgba(3,148,180,1) 0%, rgba(6,54,122,1) 100%);
  justify-content: center;
  align-items: center;
  .inscription_form{
    width: 33rem;
    background-color: rgba(var(--bg-color-raw),0.7);
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    border-radius: var(--border-radius);
    img{
      //position: absolute;
      //top: 0;
      //left: 1rem;
      width: 40%;
      padding: 1rem 30%;
      background-color: rgba(var(--bg-color-raw),1);
      border-radius: var(--border-radius) var(--border-radius) 0 0;
    }
    form{
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      gap: 1rem;
      padding: 1rem;
      color: var(--main-color);
      &>*{
        width: calc(50% - 0.5rem);
      }
      .fullwidth{
        width: 100%;
      }
    }
  }
}