@import '../../styles/variables';

.page_logs{
  .titre{
    flex-grow: 1;
  }
  .en-tete-pages .en-tete-page {
    width: calc(100% - 2rem);
    margin: 1rem 1rem 0 1rem;
  }

  h1{
    margin: 1rem 0;
  }

  h2 {
    display: flex;
    align-items: center;
    gap: 0.2rem;
    font-size: var(--texte-size-normal);
    font-weight: lighter;
    color: var(--main-color);

    strong {
      font-weight: normal;
    }
    &:hover{
      filter: brightness(200%);
    }
  }

}


@media screen and (max-width:$responsive-tablette) {

}
