@import '../../../../styles/variables';

.horaires_maintenance{
  font-size: var(--texte-size-petit);
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 1rem;

  .horaire{
    border: solid 1px var(--main-color);
    padding: 0.2rem;
    border-radius: var(--border-radius);
    cursor: pointer;
  }
  button{
    margin: unset;
  }

}



