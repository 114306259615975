@import '../../../styles/variables';

.esti-cout{
  width: fit-content;
  margin: 0 auto;
  max-width: calc(100% - 2rem);
  //align-items: flex-start !important;
  ul, .description{
    margin-left: 1rem;
    font-size: var(--texte-size-petit);
    text-align: justify;
  }
  .description{
    i{
      margin-left: 0.5rem ;
      display: block;
    }
  }
  input:not([type=checkbox])
  {
    width: 100% !important;
  }
  .esti_prix_carte{
    max-width: calc(50% - 0rem);
    display: flex;
    flex-flow: column nowrap;
    gap: 0.5rem;
    background-color: var(--bg-color);
    padding: 0.5rem;
    border-radius: var(--border-radius);
    flex: 1;
    margin: 0 auto;
  }
  .image_produit{
    width: 5rem !important;
    height: 5rem !important;
    object-fit: contain;
    margin: 0 !important;
  }
  .input-half-width{
    width: calc(50% - 0.5rem);
  }
  .tr-ligne-cellule{
    vertical-align: baseline;
    td:first-child{
      width: 25%;
    }
  }
}
.portal_menu_max_height{
  .mantine-Menu-dropdown{
    overflow: auto;
    max-height: 50vh;
  }

}

@media screen and (max-width: $responsive-tablette) {
  /* Tablette & phone, on cache les menus */
  .esti-cout{
    .wrapper-cartes-esti-prix{
      flex-flow: column nowrap;
    }
    .esti_prix_carte{
      max-width: calc(100% - 1rem);
    }
    .tr-ligne-cellule{
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      margin-bottom: 1rem;
      gap: 0.2rem;
      background-color: var(--bg-color);
      border-radius: var(--border-radius);
      .tr-esti-fw{
        width: calc(100% - 1.5rem);
        justify-content: flex-start;
      }
      .inline-tag.green{
        //margin: auto auto auto 1.5rem;
        //width: calc(100% - 4.1rem);
        margin-bottom: 0.5rem;
      }
      td:first-child{
        width: 100%;
      }
    }
  }
  .esti-prix-grid-mobile{
    display: grid;
  }
}