@import '../../../styles/variables';

.page-facture-pdf {

  background-color: white;
  padding: 7px;

  .logoimg{
    width: auto;
    height: 1.5rem;
  }

  .en-tete-pdf-droite{
    display: flex;
    flex-flow: column nowrap;
    align-items: end;
  }

  h1{
    font-size: 1.5rem;
    color: var(--main-color)
  }
  h2{
    font-size: 2rem;
    color: black
  }

  section{
    //padding: 7rem;
    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;
    width: 100%;
    box-sizing: border-box;
    background-color: white;
    //margin-top: 8rem;
    page-break-after: always;
    padding-top: 1rem;
    //&:first-of-type{
    //  margin-top: 0;
    //}
    &:last-of-type{
      page-break-after: avoid;
    }
  }
  table{
    border-collapse: collapse;
  }

  th{
    text-align: left;
    border: solid 1px rgb(155 155 155);
    padding: 0.5rem;
  }
  td{
    border: solid 1px rgb(155 155 155);
    padding: 0.5rem;
    font-weight: normal;
  }

  .inline-tag{
    margin-left: auto;
    margin-right: auto;
  }

  .excursions{
    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;
    width: 100%;
    box-sizing: border-box;
    page-break-inside: avoid;
  }

  .partie-freshtime{
    display: flex;
    flex-flow: column nowrap;
  }

  .partie-client{
    display: flex;
    flex-flow: column nowrap;
    align-items: end;
  }

  .description{
    font-size: 0.7rem;
    font-weight: normal;
  }
}