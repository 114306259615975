@import '../../../styles/variables';

.tableauContainer.tableau_condition{
  .nom_condition{
    font-size: var(--texte-size-petit);
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }
  th{
    font-weight: normal;
    padding: 0;
    &.user_hover{
      color: var(--main-light-color);
    }
  }
  td{
    padding: 0.5rem;
    .ligne_checkbox{
      //display: flex;
      //flex-flow: row wrap;
      //align-items: center;
      //.mantine-Checkbox-root{
      //  padding-left: 0.2rem;
      //}
      display: grid;
      grid-template-columns: repeat(2, auto);
      grid-gap: 0 0.2rem;
    }
    &.user_hover{
      //background-color: #d9d9d9;
    }
  }

}
