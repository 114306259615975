@import '../../../styles/variables';

.page_contacts{
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  .en-tete-page{
    //width: calc(100% - 0rem);
    //margin: 0 1rem 1rem 1rem;
    justify-content: space-between;
  }
  .en-tete-pages{
    width: 100%;
  }

}