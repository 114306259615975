@import '../../../../styles/variables';

$gap_entre_mesure: 0.5rem;

.mesures{
  &.mesures_hide_conditions .carte_mesure .conditions{
    display: none !important;
  }

  &.mesures_1 .carte_mesure{
    $nbe_mesures_par_lignes: 1;
    width: calc( (100%/$nbe_mesures_par_lignes) - ((( ($nbe_mesures_par_lignes - 1) * $gap_entre_mesure) / $nbe_mesures_par_lignes) ) );
  }
  &.mesures_2 .carte_mesure{
    $nbe_mesures_par_lignes: 2;
    width: calc( (100%/$nbe_mesures_par_lignes) - ((( ($nbe_mesures_par_lignes - 1) * $gap_entre_mesure) / $nbe_mesures_par_lignes) ) );
  }
  &.mesures_3 .carte_mesure{
    $nbe_mesures_par_lignes: 3;
    width: calc( (100%/$nbe_mesures_par_lignes) - ((( ($nbe_mesures_par_lignes - 1) * $gap_entre_mesure) / $nbe_mesures_par_lignes) ) );
  }
  &.mesures_4 .carte_mesure{
    $nbe_mesures_par_lignes: 4;
    width: calc( (100%/$nbe_mesures_par_lignes) - ((( ($nbe_mesures_par_lignes - 1) * $gap_entre_mesure) / $nbe_mesures_par_lignes) ) );
  }
  &.mesures_5 .carte_mesure{
    $nbe_mesures_par_lignes: 5;
    width: calc( (100%/$nbe_mesures_par_lignes) - ((( ($nbe_mesures_par_lignes - 1) * $gap_entre_mesure) / $nbe_mesures_par_lignes) ) );
  }
  &.mesures_6 .carte_mesure{
    $nbe_mesures_par_lignes: 6;
    width: calc( (100%/$nbe_mesures_par_lignes) - ((( ($nbe_mesures_par_lignes - 1) * $gap_entre_mesure) / $nbe_mesures_par_lignes) ) );
  }
  &.mesures_7 .carte_mesure{
    $nbe_mesures_par_lignes: 7;
    width: calc( (100%/$nbe_mesures_par_lignes) - ((( ($nbe_mesures_par_lignes - 1) * $gap_entre_mesure) / $nbe_mesures_par_lignes) ) );
  }
  &.mesures_8 .carte_mesure{
    $nbe_mesures_par_lignes: 8;
    width: calc( (100%/$nbe_mesures_par_lignes) - ((( ($nbe_mesures_par_lignes - 1) * $gap_entre_mesure) / $nbe_mesures_par_lignes) ) );
  }
  &.mesures_9 .carte_mesure{
    $nbe_mesures_par_lignes: 9;
    width: calc( (100%/$nbe_mesures_par_lignes) - ((( ($nbe_mesures_par_lignes - 1) * $gap_entre_mesure) / $nbe_mesures_par_lignes) ) );
  }
  &.mesures_10 .carte_mesure{
    $nbe_mesures_par_lignes: 10;
    width: calc( (100%/$nbe_mesures_par_lignes) - ((( ($nbe_mesures_par_lignes - 1) * $gap_entre_mesure) / $nbe_mesures_par_lignes) ) );
  }

  &.mesures_mode_compact .carte_mesure .valeur{
    min-height: unset;
  }


}
