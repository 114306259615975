@import '../../../../styles/variables';


.modale_graphique{


  .mesures_historique{
    overflow-x: unset;
    overflow-y: unset;
  }
  h1{
    color: var(--main-color);
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .mantine-Modal-content{
    flex: 1; //full screen
  }

  .mantine-DateTimePicker-input{
    height: unset;
    line-height: unset;
    min-height: 1.6rem;
  }

  .mantine-Modal-content .mantine-Modal-body, .mantine-Modal-content {
    max-height: unset !important;
    padding-bottom: 0;
    background-color: var(--bg-color);
  }
  .mantine-Modal-header{
    background-color: var(--bg-color);
  }

  .mesures.mesures_6 {
    height: calc(100dvh - 6rem);
  }

  .tableauContainer{
    max-width: 100%;
  }

}

@media screen and (max-width:$responsive-tablette) {
  .modale_graphique{
    .mesures.mesures_6 .carte_mesure{
      $nbe_mesures_par_lignes: 3;
      width: calc( (100%/$nbe_mesures_par_lignes) - ((( ($nbe_mesures_par_lignes - 1) * 0.5rem) / $nbe_mesures_par_lignes) ) ) !important;
    }

    .carte_mesure.green{
      //background-color: var(--bg-color) !important;
      //color: var(--main-color)
    }
    .mantine-Modal-inner {
      padding: 0rem;
    }
    .mantine-Modal-content .mantine-Modal-body, .mantine-Modal-content {
      max-height: calc(100% - 0rem) !important;
      padding-bottom: 3rem;
      background-color: var(--bg-color);
    }
  }

}