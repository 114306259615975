@import 'variables';

a{
  color: var(--main-light-color);
  font-size: inherit;
  text-decoration: none;
}

button, a.btn{
  border: none;
  border-radius: var(--border-radius);
  background-color: var(--main-light-color);
  padding: 0.3rem 0.6rem;
  font-size: 0.8rem;
  color: white;
  margin: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
  &:not(:disabled):hover{
    filter: brightness(120%);
  }
  &.mantine-Input-input:hover{
    filter: unset;
  }
  &.big{
    padding: 0.3rem 0.6rem;
    font-size: 1rem;
    &:disabled{
      filter: saturate(0%);
      cursor: not-allowed;
    }
  }
  &.sec{
    box-shadow: var(--main-light-color) 0 0 0 1.4px inset;
    background-color: transparent;
    color: var(--main-light-color);
    &.rouge{
      color: var(--error-color);
      box-shadow: var(--error-color) 0 0 0 1.4px inset;
    }
    &.vert{
      color: var(--valid-color);
      box-shadow: var(--valid-color) 0 0 0 1.4px inset;
    }
    &.blanc{
      color: white;
      box-shadow: white 0 0 0 1.4px inset;
    }
    &:disabled{
      color: #888;
      box-shadow: #888 0 0 0 1.4px inset;
      cursor: not-allowed;
    }
  }
  &.sec:not(:disabled):hover{
    background-color: var(--main-light-color);
    color: white;
    &.rouge{
      background-color: var(--error-color);
    }
  }
  &.red{
    //box-shadow: var(--main-light-color) 0 0 0 1.4px inset;
    background-color: var(--error-color);
    color: white;
  }
  &.vert{
    background-color: var(--valid-color);
    color: white;
  }
  &.gris{
    background-color: #888;
    color: white;
  }
  &:not(.sec, .mantine-DatePicker-day, .mantine-Menu-item):disabled{
    background-color: #888;
    cursor: not-allowed;
  }
  i{
    height: 0.8rem;
  }
  &.mantine-Tabs-tab{
    margin: unset;
    flex: 1;
    gap: 0;
  }
}

a:not(.btn):hover
{
  filter: brightness(120%);
}


code{
  background-color: #171717;
  color: #f1f1f1;
  width: 100%;
  margin-top: 1rem;
  padding: 1rem;
  //border: solid 2px black;
  border-radius: var(--border-radius);
  font-family: 'Courier New', monospace;
  font-size: 16px;
  display: block;
  &>*{
    font-family: inherit;
  }
}

h1{
  line-height: 2.5rem;
  font-size: 2rem;
}