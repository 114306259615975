@import '../../../styles/variables';

.en-tete-pages {

  .en-tete-page{
    width: calc(100% - 2rem);
    margin: 0 1rem 1rem 1rem;
    justify-content: space-between;
    flex-wrap: nowrap;
    //align-items: end;
    .select_nbe_lignes
    {
      //width: 5rem;
      select{
        padding: 0.15rem 1.6rem 0.15rem 0.6rem;
      }
    }
  }

}