@import '../../../styles/variables';

.app.login{
  height: 100svh;
  background: rgb(3,148,180);
  background: linear-gradient(-15deg, rgba(3,148,180,1) 0%, rgba(6,54,122,1) 100%);
  justify-content: center;
  align-items: center;
  .login_form{
    //width: 32%;
    width: 33rem;
    background-color: rgba(var(--bg-color-raw),0.7);
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    border-radius: var(--border-radius);
    img{
      width: 70%;
      padding: 2rem 15%;
      background-color: rgba(var(--bg-color-raw),1);
      border-radius: var(--border-radius) var(--border-radius) 0 0;
    }
    form{
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      gap: 1rem;
      padding: 1rem;
      color: var(--main-color);
      &>*{
        width: calc(50% - 0.5rem);
      }
      button.big{
        background-color: #0874BC;
        margin: unset;
        width: calc(50% - 1.75rem);
        padding: 0.5rem 0.6rem;
      }
      .mantine-Input-input{
        box-shadow: none;
        height: 2rem;
        border-radius: var(--border-radius);
      }
      .notice{
        width: 100%;
      }
      .mantine-TextInput-root, .mantine-PasswordInput-root{
        margin-top: 0.25rem;
      }
    }
  }
  .btn_inscri{
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    width: calc(100% - 4rem);

  }
}

@media screen and (max-width: $responsive-laptop13) {
  /* Ecran HP Envy 13" */
  .app.login .login_form{
    //width: 36%;
  }
}
@media screen and (max-width: $responsive-laptop) {
  /* Ecran laptop standard */
  .app.login .login_form{
    //width: 40%;
  }
}
@media screen and (max-width: $responsive-TV720) {
  /* Ecran TV 720p */
}
@media screen and (max-width: $responsive-tablette) {
  /* Tablette & phone, on cache les menus */
}