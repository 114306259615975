@import 'variables';

.Tabs{
  .mantine-Tabs-tabsList{
    button{
      flex: 1;
    }
    button:hover{
      filter: unset
    }
  }

}

.modale-width-libre{
  flex: unset;
}
.mantine-Modal-overlay{
  backdrop-filter: blur(10px);
}
.mantine-Modal-content{
  overflow-y: unset!important;
  max-height: 100%;
  border-radius: var(--border-radius);

  .mantine-Modal-body{
    overflow: scroll !important;
    //max-height: calc(100vh - 30vh - 5rem);
    max-height: calc(70vh - 4rem);
    //max-height: 70vh;
  }
}



.mantine-Modal-header{
  border-radius: var(--border-radius);
  min-height: unset;
}
.mantine-Modal-inner{
  //width: calc(100% - 10vw);
  width: 100%;
  padding-top: 15vh;
  padding-bottom: 15vh;
  align-items: center;

  //align-items: center;
  .mantine-Modal-content{
    //flex-basis: 50%;
  }
  .form{
    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;
  }
  input:not(.mantine-MultiSelect-searchInput, .mantine-MultiSelect-inputField){
    //border: solid var(--main-color) 1px;
    box-shadow: var(--main-color) 0 0 0 1px inset;
  }
}
.mantine-Popover-dropdown, .mantine-Modal-inner{
  .description{
    font-size: var(--texte-size-petit);
    white-space: break-spaces;
    opacity: 70%;
    font-weight: lighter;
  }
}

.modal_hide{
  .mantine-Modal-content{
    filter: blur(4px) brightness(50%);
    pointer-events: none;
  }
  //display: none;
}
.modal-en-tete-custom{
  display: flex;
  flex-flow: row nowrap;
  justify-content: end;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;

  button{
    margin: unset;
  }
  p.titre{
    margin-right: auto;
  }
}

.modale-fullscreen{
  .mantine-Modal-content{
    width: 100%;
    flex: unset;
    .mantine-Modal-body {
      max-height: calc(89vh - 5rem);
    }
  }
  .mantine-Modal-inner{
    padding: 3vw;
    //width: calc(100% - 6vw);
    width: 100%;
  }
}

.boutons_bas_modale{
  margin-top: 2rem;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  border-top: solid 1px rgba(96, 96, 96, 0.39);
  //box-shadow: rgba(80, 80, 80, 0.37) 0 -3px 5px;
  padding: 0.4rem;
  border-radius: 0 0 var(--border-radius) var(--border-radius);
}
.padding_bottom_modale_quand_boutons_fixed{
  .mantine-Modal-content .mantine-Modal-body{
    padding-bottom: 3.5rem;
  }

}


.mantine-Menu-dropdown.maxheight50{
  overflow-y: scroll;
  max-height: 50vh;
}



@media screen and (max-width: $responsive-laptop13) {
  /* Ecran HP Envy 13" */
}
@media screen and (max-width: $responsive-laptop) {
  /* Ecran laptop standard */
}
@media screen and (max-width: $responsive-TV720) {
  /* Ecran TV 720p */
}
@media screen and (max-width: $responsive-tablette) {
  /* Tablette & phone, on cache les menus */
  .mantine-Modal-inner {
    //width: calc(100% - 2rem);
    width: 100%;
    padding: 1rem;

    align-items: center;
  }
  .mantine-Modal-content .mantine-Modal-body {
    max-height: calc(100dvh - 8rem);
  }
}

