@import '../../../../styles/variables';

.mesure_config_modale{
  h2{
    color: var(--main-color);
    font-size: var(--texte-size-titre);
    line-height: 2rem;
  }
  .mantine-Modal-body{
    max-height: 70vh; //N'a pas besoin de calc - 4rem puisque n'a pas de header à la modale
  }
  .mantine-Modal-content{
    flex-basis: 50rem;
  }
  .table_statuts{
    width: 100%;
    tbody:before{
      content: unset !important;
    }
    td, th{
      font-size: var(--mantine-font-size-sm);
    }
  }

  .wrapper_enregistrer_cb{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
  }
}

.encart_detail_ordre_cellules{
  background: var(--bg-color);
  padding: 0.5rem;
  border-radius: var(--border-radius);
  .cellules{
    font-size: var(--texte-size-petit);
    margin-left: 2rem;
  }
}

@media screen and (max-width:$responsive-tablette) {
  .mesure_config_modale .mantine-Stepper-steps{
    display: none;
  }
  .mesure_config_modale .mantine-Stepper-content{
    padding-top: 0;
  }
}

@media screen and (max-width:$responsive-tablette) and (orientation: portrait) {

  .mesure_config_modale{
    .table_statuts {
      //display:block;
      display: flex;
      flex-flow: column wrap;

      .th_mobile{
        display:block !important;
        margin-right: 0.25rem;
        margin-left: 0.25rem;
      }
    }
    .table_statuts>*,
    table tr,
    table td,
    table th {
      display:block;
      text-align: left !important;
    }
    .table_statuts thead {
      //display:none
      //order: 99;
      padding-bottom: 1rem;
      &:before{
        content: "Tri et recherches : ";
        font-size: var(--texte-size-normal);
      }
      tr{
        //border-radius: var(--border-radius);
        th{
          padding: 0rem 0em;
          span{
            justify-content: start;
            font-size: var(--texte-size-normal);
          }
          input.input_recherche_table{
            background-color: white;
            width: calc(100% - 1.25rem);
          }
        }
      }
      display: none;
    }
    .table_statuts{
      background-color: unset;
      .en-ligne{
        justify-content: center;
      }
    }
    .table_statuts tbody {
      display: flex;
      flex-flow: column wrap;
      gap: 1rem;
    }
    .table_statuts tbody tr {
      height:auto;
      //padding:37px 0 // ????
      padding: 0;
      border-radius: var(--border-radius);
      display: flex;
      flex-flow: column nowrap;
      margin-bottom: 1rem;
      td {
        border: none !important;
      }
    }

    span.aligne_left{
      text-align: center;
    }
  }



}