@import '../../../styles/variables';

.content.page_mesure{
  h1 {
    text-align: center;
    font-size: var(--texte-size-titre);
    margin: 1rem;
    color: var(--main-color);
    line-height: 1rem;
  }

  h2 {
    display: flex;
    align-items: center;
    gap: 0.2rem;
    font-size: var(--texte-size-normal);
    font-weight: lighter;
    color: var(--main-color);
    width: fit-content;

    strong {
      font-weight: normal;
    }
  }
  .mesures_nav{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    .options{
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      flex: 1;
      & button.sec, & .mantine-Input-wrapper button {
        background-color: white;
      }
      & button.sec:hover{
        background-color: var(--main-color);
      }
      &:last-of-type{
        justify-content: flex-end;
        button{
          margin: unset;
        }
      }
    }
  }

  .mantine-Input-input {
    padding: .15rem 1rem;
  }

  .excursions{
    .tableauContainer{
      max-width: calc(80vw - 21rem);
      //max-width: 100%;
    }
  }

}




@media screen and (max-width: $responsive-laptop13) {
  /* Ecran HP Envy 13" */
}
@media screen and (max-width: $responsive-laptop) {
  /* Ecran laptop standard */
}
@media screen and (max-width: $responsive-TV720) {
  /* Ecran TV 720p */
  .page_mesure{

    .mesures_nav{
      justify-content: space-evenly;
      .options{
        gap: 0.25rem;
        flex: unset;
      }
    }
  }
}
@media screen and (max-width: $responsive-tablette) {
  /* Tablette & phone, on cache les menus */
  .page_mesure{

    .mesures_nav{

      .options{
        &:last-of-type{
          justify-content: center;
        }

        .mantine-DateTimePicker-root{
          //order: 99;
        }
      }
    }

  }
  .content.page_mesure{
    .excursions{
      .tableauContainer{
        max-width: calc(100vw - 2rem);
        //max-width: 100%;
      }
    }
  }

}