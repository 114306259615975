@import 'variables';

//label{
//  display: flex;
//  flex-flow: column nowrap;
//  &.checkbox{
//    flex-flow: row wrap;
//    gap: 0.5rem;
//  }
//}
input{
  border: none;
  border-radius: var(--border-radius);
  background-color: white;
  padding: 0.2rem 0.6rem;
  font-size: 1rem;
  //&[type="Checkbox"]{
  //  width: 1rem;
  //}
}
select{
  min-width: fit-content;//Ces deux prop pour le select Affichage de la page mesure
  padding-right: 1.5rem;
}



.mantine-Input-input{
  border-radius: var(--border-radius);
  border: unset;
  //min-height: unset;
  box-shadow: var(--main-light-color) 0 0 0 1px inset;
  color: var(--main-light-color);
  font-size: var(--texte-size-petit);
  font-family: "Lato", sans-serif;
  background-color: white;
  //padding: 0.15rem 1rem;
  box-sizing: border-box;

  //white-space: nowrap;
  &.mantine-PasswordInput-input{
  }
  &[data-invalid]{
    box-shadow: #fa5252 0 0 0 1px inset;
    input{
      //color: gold;
    }

  }

}



.match_height{ /* Pour les input mantine */
  .mantine-Input-input{
    height: unset;
    line-height: unset;
    min-height: 1.6rem;
  }
  .mantine-DateInput-input{
    width: 7.5rem;
    text-align: center; // Pour le dateInput dans Mesures, quand affichage sur jour
  }
}
.mantine-Checkbox-label{
  padding-left: 0.2rem;
}
.mantine-DateTimePicker-input{
  white-space: nowrap;
}
//.mantine-Switch-trackLabel{
//  margin: unset !important;
//}
//.mantine-Switch-track{
//  height: 1.3rem;
//}
//.mantine-Switch-thumb{
//  top: 0.15rem;
//}
//
.mantine-Switch-body{
  align-items: center;
}
//
//.mantine-Switch-description{
//  margin-top: 0;
//}

.select_margin_right{
  .mantine-Input-input{
    padding: 0 1.5rem 0 1rem !important;
  }
}

.mantine-Modal-close{
  margin: inherit;
}

.mantine-Combobox-dropdown{
  overflow: auto;
  max-height: 37vh;
}

.mantine-Menu-item, a.mantine-Menu-item, a.mantine-Menu-item:hover{
  filter: unset !important;
}

[data-mantine-color-scheme='light'] .m_9307d992 {
  --switch-bg: #afafaf;
}